import React, { useEffect } from "react";
import upwork from '../assets/images/pngimages/upwork.png';
import linkdin from '../assets/images/pngimages/likdin.png';
import contact from '../assets/images/pngimages/contact.png';




const Contact = () => {
    useEffect(() => {
        setTimeout(function () {
          window.scrollTo(0, 0);
        }, 100);
      }, []);
    return (
        <>

            <div className="container" style={{ marginTop: '100px' }}>
                <div className="contact-details">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="contact-inner-wrapper">
                                <img src={upwork} alt="Upwork" />
                                <h3>Upwork</h3>
                                <a href="https://www.upwork.com/agencies/squadminds/" target="_blank">squadminds</a>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="contact-inner-wrapper">
                                <img src={linkdin} alt="Linkedin" />
                                <h3>Linkedin</h3>
                                <a href="https://in.linkedin.com/company/squadmindspvtltd" target="_blank">squadmindspvtltd</a>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="contact-inner-wrapper">
                                <img src={contact} alt="Contact" />
                                <h3>Contact</h3>
                                <p className="m-0">Landline: <a href="tel:+911724102716">0172-4102716</a></p>
                                <p className="m-0">E-mail: <a href="mailto:info@squadminds.com">info@squadminds.com</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="map" style={{ marginTop: '70px' }}>
                <iframe
                    title="map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3430.2895043287267!2d76.68566301513097!3d30.71026068164504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390feff786fc6e13%3A0xf58055bb138c0c8f!2sSquadMinds%20Private%20Limited!5e0!3m2!1sen!2sin!4v1678854435850!5m2!1sen!2sin"
                    width="100%" height="500" style={{ border: 0 }} allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>

        </>
    )
}

export default Contact
