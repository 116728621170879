import React, { useState, useEffect } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ProjectDetails } from "./ProjectDetails";
import { useLocation, useNavigate } from 'react-router-dom';
const Portfolio = () => {
    const [key, setKey] = useState('All');
    const [showButton, setShowButton] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [projDetails, setProjDetails] = useState();
    const [filterProjects, setFilterProjects] = useState()
    const location = useNavigate();
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        const wordpressProjects = ProjectDetails.filter(project => project.classes?.toLowerCase().includes(key));
        setFilterProjects(wordpressProjects)
    }, [key])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    const handleProjDetails = (item) => {
        setShowModal(!showModal)
        setProjDetails(item)
    };

    const handleCloseModal = (item) => {
        setShowModal()
    }
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const category = searchParams.get('category') || 'All';
        setKey(category);

        const filteredProjects = ProjectDetails.filter(project =>
            category === 'All' || project.classes?.toLowerCase().includes(category.toLowerCase())
        );
        setFilterProjects(filteredProjects);
    }, [location.search]);

    const handleTabSelect = (k) => {
        setKey(k);
        const path = k === 'All' ? '/portfolio' : `/portfolio?${k}`;
        location(path);
    };
    useEffect(() => {
        setTimeout(function () {
          window.scrollTo(0, 0);
        }, 100);
      }, []);
    return (
        <>
            {showButton && (
                <a className="scroll-to-top-button" onClick={scrollToTop}>
                </a>
            )}
            {/* <Container> */}
            <div style={{ marginTop: '126px' }} >
                <section className="portfolio-wrapper" id="Portfolo">
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        // onSelect={(k) => setKey(k)}
                        onSelect={handleTabSelect}
                        className="mb-0 text-center"
                    >
                        <Tab eventKey="All" title="All">
                            <div className="container">
                                <div className="row">
                                    {ProjectDetails.map((item, index) => {
                                        return (
                                            <>
                                                <div className="col-md-4 mb-4" key={index}>
                                                    <div className="portfio-tabs">
                                                        <div className="projects-wrap position-relative" onClick={() => handleProjDetails(item)}>
                                                            <div className="project-img portfolio portfolio-img">
                                                                <img src={item.image} style={{ cursor: 'pointer' }} />
                                                            </div>
                                                            <div className="portfolio-txt text-center">
                                                                <h4>{item.name}</h4>
                                                                <h5>{item.lang}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="wordpress" title="Wordpress" tabIndex="123456">
                            <div className="container">
                                <div className="row">
                                    {filterProjects?.map((item, index) => {
                                        return (
                                            <>
                                                <div className="col-md-4 mb-4" key={index}>
                                                    <div className="portfio-tabs">
                                                        <div className="projects-wrap position-relative" onClick={() => handleProjDetails(item)}>
                                                            <div className="project-img portfolio portfolio-img">
                                                                <img src={item.image} style={{ cursor: 'pointer' }} />
                                                            </div>
                                                            <div className="portfolio-txt text-center">
                                                                <h4><a href='' tabIndex="_blank">{item.name}</a></h4>
                                                                <h5>{item.lang}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="ruby" title="Ruby on Rails">
                            <div className="container">
                                <div className="row">
                                    {filterProjects?.map((item, index) => {
                                        return (
                                            <>
                                                <div className="col-md-4 mb-4" key={index}>
                                                    <div className="portfio-tabs">
                                                        <div className="projects-wrap position-relative" onClick={() => handleProjDetails(item)}>
                                                            <div className="project-img portfolio portfolio-img">
                                                                <img src={item.image} style={{ cursor: 'pointer' }} />
                                                            </div>
                                                            <div className="portfolio-txt text-center">
                                                                <h4><a href='' tabIndex="_blank">{item.name}</a></h4>
                                                                <h5>{item.lang}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="laravel" title="Laravel">
                            <div className="container">
                                <div className="row">
                                    {filterProjects?.map((item, index) => {
                                        return (
                                            <>
                                                <div className="col-md-4 mb-4" key={index}>
                                                    <div className="portfio-tabs">
                                                        <div className="projects-wrap position-relative" onClick={() => handleProjDetails(item)}>
                                                            <div className="project-img portfolio portfolio-img">
                                                                <img src={item.image} style={{ cursor: 'pointer' }} />
                                                            </div>
                                                            <div className="portfolio-txt text-center">
                                                                <h4><a href='' tabIndex="_blank">{item.name}</a></h4>
                                                                <h5>{item.lang}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}

                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="reactjs" title="Reactjs">
                            <div className="container">
                                <div className="row">
                                    {filterProjects?.map((item, index) => {
                                        return (
                                            <>
                                                <div className="col-md-4 mb-4" key={index}>
                                                    <div className="portfio-tabs">
                                                        <div className="projects-wrap position-relative" onClick={() => handleProjDetails(item)}>
                                                            <div className="project-img portfolio portfolio-img">
                                                                <img src={item.image} style={{ cursor: 'pointer' }} />
                                                            </div>
                                                            <div className="portfolio-txt text-center">
                                                                <h4><a href='' tabIndex="_blank">{item.name}</a></h4>
                                                                <h5>{item.lang}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="jango" title="Django">
                            <div className="container">
                                <div className="row">
                                    {filterProjects?.map((item, index) => {
                                        return (
                                            <>
                                                <div className="col-md-4 mb-4" key={index}>
                                                    <div className="portfio-tabs">
                                                        <div className="projects-wrap position-relative" onClick={() => handleProjDetails(item)}>
                                                            <div className="project-img portfolio portfolio-img">
                                                                <img src={item.image} style={{ cursor: 'pointer' }} />
                                                            </div>
                                                            <div className="portfolio-txt text-center">
                                                                <h4><a href='' tabIndex="_blank">{item.name}</a></h4>
                                                                <h5>{item.lang}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="node" title="Node js">
                            <div className="container">
                                <div className="row">
                                    {filterProjects?.map((item, index) => {
                                        return (
                                            <>
                                                <div className="col-md-4 mb-4" key={index}>
                                                    <div className="portfio-tabs">
                                                        <div className="projects-wrap position-relative" onClick={() => handleProjDetails(item)}>
                                                            <div className="project-img portfolio portfolio-img">
                                                                <img src={item.image} style={{ cursor: 'pointer' }} />
                                                            </div>
                                                            <div className="portfolio-txt text-center">
                                                                <h4><a href='' tabIndex="_blank">{item.name}</a></h4>
                                                                <h5>{item.lang}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}

                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="native" title="React Native">
                            <div className="container">
                                <div className="row">
                                    {filterProjects?.map((item, index) => {
                                        return (
                                            <>
                                                <div className="col-md-4 mb-4" key={index}>
                                                    <div className="portfio-tabs">
                                                        <div className="projects-wrap position-relative" onClick={() => handleProjDetails(item)}>
                                                            <div className="project-img portfolio portfolio-img">
                                                                <img src={item.image} style={{ cursor: 'pointer' }} />
                                                            </div>
                                                            <div className="portfolio-txt text-center">
                                                                <h4><a href='' tabIndex="_blank">{item.name}</a></h4>
                                                                <h5>{item.lang}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}

                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="firebase" title="Firebase">
                            <div className="container">
                                <div className="row">
                                    {filterProjects?.map((item, index) => {
                                        return (
                                            <>
                                                <div className="col-md-4 mb-4" key={index}>
                                                    <div className="portfio-tabs">
                                                        <div className="projects-wrap position-relative" onClick={() => handleProjDetails(item)}>
                                                            <div className="project-img portfolio portfolio-img">
                                                                <img src={item.image} style={{ cursor: 'pointer' }} />
                                                            </div>
                                                            <div className="portfolio-txt text-center">
                                                                <h4><a href='' tabIndex="_blank">{item.name}</a></h4>
                                                                <h5>{item.lang}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="shopify" title="Shopify">
                            <div className="container">
                                <div className="row">
                                    {filterProjects?.map((item, index) => {
                                        return (
                                            <>
                                                <div className="col-md-4 mb-4" key={index}>
                                                    <div className="portfio-tabs">
                                                        <div className="projects-wrap position-relative" onClick={() => handleProjDetails(item)}>
                                                            <div className="project-img portfolio portfolio-img">
                                                                <img src={item.image} style={{ cursor: 'pointer' }} />
                                                            </div>
                                                            <div className="portfolio-txt text-center">
                                                                <h4><a href='' tabIndex="_blank">{item.name}</a></h4>
                                                                <h5>{item.lang}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="vue.js" title="Vue.js" tabIndex="123456">
                            <div className="container">
                                <div className="row">
                                    {filterProjects?.map((item, index) => {
                                        return (
                                            <>
                                                <div className="col-md-4 mb-4" key={index}>
                                                    <div className="portfio-tabs">
                                                        <div className="projects-wrap position-relative" onClick={() => handleProjDetails(item)}>
                                                            <div className="project-img portfolio portfolio-img">
                                                                <img src={item.image} style={{ cursor: 'pointer' }} />
                                                            </div>
                                                            <div className="portfolio-txt text-center">
                                                                <h4><a href='' tabIndex="_blank">{item.name}</a></h4>
                                                                <h5>{item.lang}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="python" title="Python" tabIndex="123456">
                            <div className="container">
                                <div className="row">
                                    {filterProjects?.map((item, index) => {
                                        return (
                                            <>
                                                <div className="col-md-4 mb-4" key={index}>
                                                    <div className="portfio-tabs">
                                                        <div className="projects-wrap position-relative" onClick={() => handleProjDetails(item)}>
                                                            <div className="project-img portfolio portfolio-img">
                                                                <img src={item.image} style={{ cursor: 'pointer' }} />
                                                            </div>
                                                            <div className="portfolio-txt text-center">
                                                                <h4><a href='' tabIndex="_blank">{item.name}</a></h4>
                                                                <h5>{item.lang}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </section>
            </div>
        </>
    )
}

export default Portfolio