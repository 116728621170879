import journalLifeImage from '../assets/images/projects/journallife1.png';
import LiteFreshVoice from '../assets/images/projects/litefresh.png';
import Beatskillz from '../assets/images/projects/beatskillz.png';
import Moxy from '../assets/images/projects/moxy.png';
import Suprajit from '../assets/images/projects/suprajit.png';
import SiestaKeyChamber from '../assets/images/projects/siestakeychamber.png';
import LogHeadsCenter from '../assets/images/projects/logheads.png';
import Diocal from '../assets/images/projects/diocese.png';
import Grailpay from '../assets/images/projects/grailpay.png';
import Topel from '../assets/images/projects/fandd.png';
import Millcreek from '../assets/images/projects/millcreek.png';
import SandDollarOne from '../assets/images/projects/sanddollarone.png';
import Omnisys from '../assets/images/projects/omnisys.png';
import Shipit from '../assets/images/projects/shipit.png';
import Reimbursement from '../assets/images/projects/reinbursement.png';
import Vectura from '../assets/images/projects/vectura.png';
import DFO from '../assets/images/projects/clockoff.png';
import VoicePing from '../assets/images/projects/voiceping.png';
import Dint from '../assets/images/projects/dint.png';
import VersusStocks from '../assets/images/projects/versusstocks.png';
import grailpayWidgetApp from '../assets/images/projects/grailpay_widget.png'
import Carnawash from '../assets/images/projects/carnawash.png';
import Docodemo from '../assets/images/projects/docodemo.png';
import TheArtCare from "../assets/images/projects/theartcare.png";
import Connectar from "../assets/images/projects/theconnectar.png";
import Willhelm from "../assets/images/projects/willhelm.png";
import healthCare from "../assets/images/projects/healthcare.png";
import Dental from "../assets/images/projects/dental.png";
import MIMOR from "../assets/images/projects/homepage-mimor.png";
import Phoenixlamps from "../assets/images/projects/phoenixlamps.png";
import VIPDNSCLUB from "../assets/images/projects/vipdnsclub.png";
import Moxymix from "../assets/images/projects/moxymix.png";
import LuxuryPickleball from "../assets/images/projects/luxurypickleball.png";
import FREDHOME from "../assets/images/projects/fred-home.png";
import MySpy from "../assets/images/projects/myspy.png";
import Bould from "../assets/images/projects/bould.png";
import OrderManagement from "../assets/images/projects/ordermanagement.png";
export const ProjectDetails = [

    {
        name: 'Beatskillz',
        lang: 'wordpress',
        classes:'wordpress',
        image: Beatskillz,
        desc: 'Beatskillz has the sound and the approach that we immediately connected with; instant inspiration and unexpected gratification.',
        Website: 'https://www.beatskillz.com/',
        status: 'completed',
        location: "USA",
        show_website: true
    },
    {
        name: 'Suprajit',
        lang: 'wordpress',
        classes:'wordpress',
        image: Suprajit,
        desc: 'The Suprajit Group is a global leader in the automotive cable and halogen bulb industry. With a competitive manufacturing base in India, UK, US and Mexico, along with our technical and logistical supports worldwide, the group provides the optimal product development and manufacturing solutions to its domestic and international customers.',
        Website: 'https://suprajit.com/',
        status: 'In progress',
        location: 'USA',
        show_website: true
    },
    {
        name: 'SiestaKeyChamber',
        lang: 'Ruby on Rails, Wordpress',
        classes:'wordpress, Ruby on Rails',
        image: SiestaKeyChamber,
        desc: 'The Siesta Key Chamber of Commerce is a membership-based, 501(c)(6) organization. Consequently, the Chamber is lead by a 15-member board of directors. Each board member serves a three year term of service to the Chamber.',
        Website: 'https://www.siestakeychamber.com/',
        status: 'Completed',
        location: 'USA',
        show_website: true
    },
    {
        name: 'LogHeadsCenter',
        lang: 'Magento, Wordpress',
        classes:'wordpress',
        image: LogHeadsCenter,
        desc: 'One of the largest manufacturers of Log Furniture in the U.S., we were approached by HGTV Extreme Makeover. They were interested in Tommy designing custom furniture for a few of their episodes and we were thrilled they chose us and so honored to have helped out families in need.',
        Website: 'https://logheadshomecenter.com/',
        status: 'Completed',
        location: 'USA',
        show_website: true
    },
    {
        name: 'Diocal',
        lang: 'Drupal, wordpress',
        classes:'Drupal, wordpress',
        image: Diocal,
        desc: 'Embodied Justice, Church Vitality, Rooted Spirituality, Transparent and Accountable Leadership, and Inclusive Community. The Beloved Community, like the Kingdom of God Jesus references in the gospels, is something we catch glimpses of here and now!',
        Website: 'https://www.diocal.org/',
        status: 'In progress',
        location: 'USA',
        show_website: true
    },
    {
        name: 'Grailpay',
        lang: 'Reactjs, Ruby on Rails, Shopify, Firebase, Laravel, Wordpress plugin',
        classes:'Reactjs, Ruby on Rails, Shopify, Firebase, Laravel, Wordpress plugin',
        image: Grailpay,
        desc: 'GrailPay is the first payments platform that turns payments from a cost center to a profit center. It is money movement, customer onboarding, and financing, all in one.',
        Website: 'https://grailpay.com/',
        status: 'In progress',
        location: 'USA',
        show_website: true
    },
    {
        name: 'Topel & DiStasi',
        lang: 'Wordpress',
        classes:'wordpress',
        image: Topel,
        desc: 'We help our clients pursue their life goals through comprehensive wealth management and financial planning expertise.',
        Website: 'https://td-wm.com/',
        status: 'Completed',
        location: 'USA',
        show_website: true

    },
    {
        name: 'journalLife',
        lang: 'Ruby on Rails',
        classes:'Ruby on Rails',
        image: journalLifeImage,
        desc: 'JournalLife is award-winning online journal to write, reflect and review your journal securely and privately. Add depth and new perspective to your journaling. JournalLife is simple, yet sophisticated journal software, created by long-time journal writers and endorsed by journal therapists, best-selling authors, and academic researchers worldwide.',
        Website: 'https://app.journallife.me/',
        status: 'In progress',
        location: "USA",
        show_website: true

    },
    {
        name: 'LiteFreshVoice',
        lang: 'Reactjs, Ruby on Rails, Firebase',
        classes:'Reactjs, Ruby on Rails, Firebase',
        image: LiteFreshVoice,
        desc: 'Internal and external meetings, web business negotiations, and web interview webinars can all be done with LFV!.',
        Website: 'https://www.freshvoice.net/',
        status: 'Completed',
        location: "USA",
        show_website: true

    },
    {
        name: 'SandDollarOne',
        lang: 'Ruby on Rails',
        classes:'Ruby on Rails',
        image: SandDollarOne,
        desc: 'Let us guide you to your perfect rental! We believe we have the best team on 30A that will lead you to your dream vacation! We love helping guests create memorable, fun vacations by providing them with beautiful beach homes. With professional local staff on call 24/7, we are here to help you have the best time on the sugar white sands and emerald waters! Our superior homes and serivce will leave you speechless!',
        Website: 'https://www.sanddollarone.com/',
        status: 'Completed',
        location: "Florida, US",
        show_website: true

    },
    {
        name: 'VoicePing',
        lang: 'Reactjs, Ruby on Rails, Firebase',
        classes:'Reactjs, Ruby on Rails, Firebase',
        image: VoicePing,
        desc: 'VoicePing is used as a virtual office tools to maximize telework productivity. VoicePing is being used as a tool to check the productivity of members using the time tracking function.',
        Website: 'https://voice-ping.com/',
        status: 'Completed',
        location: "Japan",
        show_website: true
    },
    {
        name: 'Millcreek',
        lang: 'Laravel, Vue.js',
        classes:'Laravel, Vue.js',
        image: Millcreek,
        desc: 'Millcreek Gardens was established in 1978, and we grow high quality container-grown Perennials, Herbs, Annuals, Ornamental Grasses, Shrubs, Ferns, and Vines.',
        Website: 'http://millcreekplants.com/',
        status: 'In progress',
        location: "USA",
        show_website: true

    },
    {
        name: 'Omnisys',
        lang: 'Laravel',
        classes:'Laravel',
        image: Omnisys,
        desc: 'OmniSYS is a technology company with 25+ years of experience working with pharmacies, payers, providers and pharmaceutical manufacturers. We leverage this unique market insight along with our pharmacy workflow expertise to deliver valuable solutions that help pharmacies thrive in the changing pharmacy landscape.',
        Website: 'https://www.omnisys.com/',
        status: 'In progress',
        location: "USA",
        show_website: true

    },
    {
        name: 'Shipit',
        lang: 'Laravel, Vue.js',
        classes:'Laravel, Vue.js',
        image: Shipit,
        desc: 'Where Shippers and Transporters Connect.Our mission is to provide you with an alternative cost-efficient marketing platform for transporting.',
        Website: 'http://www.shipitweb.com/',
        status: 'In progress',
        location: "USA",
        show_website: true

    },
    {
        name: 'Vectura',
        lang: 'Reactjs, Nodejs, Nextjs, ChatGPT',
        classes:'Reactjs, Nodejs, Nextjs, ChatGPT',
        image: Vectura,
        desc: 'Your one-stop-shop for top-notch design solutions. At Vectura, you can understand the importance of having a well-designed brand, logo, website, or any other form of visual representation.',
        Website: 'https://www.vectura.io/',
        status: 'Completed',
        location: "Japan",
        show_website: true
    },
    {
        name: 'Docodemo',
        lang: 'Reactjs',
        classes:'Reactjs',
        image: Docodemo,
        desc: 'By using DOKODEMO Door\'s free library of content, anyone can easily create their own World and hold an event.Create a unique World full of realism from more than 1000 locations from 100 countries around the globe!',
        Website: 'https://dokodemodoors.com/en/',
        status: 'Completed',
        location: "USA",
        show_website: true

    },
    
    {
        name: 'EO Reimbursement',
        lang: 'Django',
        classes:'Django',
        image: Reimbursement,
        desc: 'Organizations have a vested interest in ensuring that reimbursements are only provided for legitimate reasons.Reimbursement is most commonly associated with business expenses. Many companies have policies outlining when they will reimburse employees for out-of-pocket expenses.',
        status: 'Completed',
        location: "USA",
        show_website: false

    },
    {
        name: 'Dint',
        lang: 'Django, Firebase',
        classes:'Django, Firebase',
        image: Dint,
        desc: 'Organizations have a vested interest in ensuring that reimbursements are only provided for legitimate reasons.Reimbursement is most commonly associated with business expenses. Many companies have policies outlining when they will reimburse employees for out-of-pocket expenses.',
        status: 'Completed',
        location: "USA",
        show_website: false

    },
    {
        name: 'DFO Scheduler',
        lang: 'Nodejs,React Native',
        classes:'Nodejs,React Native',
        image: DFO,
        desc: 'A website to record and manage time for your various jobs.',
        Website: 'https://app.dfobobcatandcartage.com/',
        status: 'Completed',
        location: "Australia",
        show_website: true

    },
    {
        name: 'Carnawash app',
        lang: 'Expo React Native',
        classes:'React Native',
        image: Carnawash,
        desc: 'Carnawash is focused on providing the most convenient and ecofriendly car wash experience available. We believe that car washing needs to be more convenient for the customer. We want to also offer outstanding opportunities for people to work for Carnawash in whatever capacity suits them and their lifestyle.',
        status: 'Completed',
        location: "Australia",
        show_website: false

    },
    {
        name: 'VersusStocks',
        lang: 'EmberJs, Firebase',
        image: VersusStocks,
        classes:'EmberJs, Firebase',
        desc: 'Develops and operates a business-to-business software platform in the United States and Canada. The company operates eXtreme Engagement Online, a platform that allows live event producers, professional sports franchises, video game publishers and developers, live event producers, and professional sports franchises, as well as other interactive media content creators, to offer in-game prizing and rewards based on the completion of in-content challenges alongside other user engagement tools.',
        Website: 'https://versusstocks.com/',
        status: 'Completed',
        location: "USA",
        show_website: true

    },
    {
        name: 'Grailpay Widget App',
        lang: 'Shopify',
        classes:'Shopify',
        image: grailpayWidgetApp,
        desc: 'GrailPay offers pay by bank (ACH) to save merchants 75%+ on payment processing costs at e-commerce checkout. GrailPay lowers the cost of payments (processing at 0.95% / transaction), offers market-leading fraud detection & dispute resolution, and provides shoppers highly rewarding payment at checkout.',
        Website: 'https://apps.shopify.com/grailpay-widget-app',
        status: 'Completed',
        location: "USA",
        show_website: true

    },
    {
        name: 'The art of care',
        lang: 'Reactjs',
        classes:'Reactjs',
        image: TheArtCare,
        desc: '',
        Website: 'https://theartofseries.com/',
        status: 'Completed',
        location: "USA",
        show_website: true
    },
    {
        name: 'The Connectar',
        lang: 'Reactjs,Nodejs',
        classes:'Reactjs,Nodejs,',
        image: Connectar,
        desc: '',
        Website: 'http://theconnectar.com/',
        status: 'Completed',
        location: "USA",
        show_website: true
    },
    {
        name: 'Willhelm Schweizer Zinnmanufaktur',
        lang: 'Django',
        classes:'Django,',
        image: Willhelm,
        desc: '',
        Website: '',
        status: 'Completed',
        location: "USA",
        show_website: true
    },
    {
        name: 'Patient Appointment System',
        lang: 'Reactjs, Django, Python',
        classes:'Reactjs, Django, Python',
        image: healthCare,
        desc: '',
        Website: '',
        status: 'Completed',
        location: "USA",
        show_website: true
    },
    {
        name: 'Dental Practice System',
        lang: 'Reactjs, Nodejs',
        classes:'Reactjs,Nodejs,',
        image: Dental,
        desc: '',
        Website: '',
        status: 'Completed',
        location: "USA",
        show_website: true
    },
    {
        name: 'MIMOR',
        lang: 'Wordpress',
        classes:'Wordpress,',
        image: MIMOR,
        desc: '',
        Website: 'https://mimor.com.au/',
        status: 'Completed',
        location: "USA",
        show_website: true
    },
    {
        name: 'Phoenixlamps',
        lang: 'Wordpress',
        classes:'Wordpress,',
        image: Phoenixlamps,
        desc: '',
        Website: 'https://phoenixlamps.co.in/',
        status: 'Completed',
        location: "USA",
        show_website: true
    },
    {
        name: 'VIPDNSCLUB',
        lang: 'Wordpress',
        classes:'Wordpress,',
        image: VIPDNSCLUB,
        desc: '',
        Website: 'https://vipdnsclub.com/',
        status: 'Completed',
        location: "USA",
        show_website: true
    },
    {
        name: 'Moxymix',
        lang: 'Wordpress',
        classes:'Wordpress,',
        image: Moxymix,
        desc: '',
        Website: 'https://moxymix.marriott.com/login/',
        status: 'Completed',
        location: "USA",
        show_website: true
    },
    {
        name: 'Luxury Pickleball',
        lang: 'Shopify',
        classes:'Shopify,',
        image: LuxuryPickleball,
        desc: '',
        Website: 'https://luxurypickleball.com/',
        status: 'Completed',
        location: "USA",
        show_website: true
    },
    {
        name: 'FREDHOME',
        lang: 'Shopify',
        classes:'Shopify,',
        image: FREDHOME,
        desc: '',
        Website: 'https://fredhome.com.au/',
        status: 'Completed',
        location: "USA",
        show_website: true
    },
    {
        name: 'MySpy',
        lang: 'Reactjs,Nodejs',
        classes:'Reactjs,Nodejs,',
        image: MySpy,
        desc: '',
        Website: '',
        status: 'Completed',
        location: "USA",
        show_website: true
    },
    {
        name: 'Bould',
        lang: 'Reactjs, Python',
        classes:'Reactjs, Python',
        image: Bould,
        desc: '',
        Website: '',
        status: 'Completed',
        location: "USA",
        show_website: true
    },
    {
        name: 'Order Management',
        lang: 'React Native',
        classes:'React Native',
        image: OrderManagement,
        desc: '',
        status: 'Completed',
        location: "Australia",
        show_website: false

    },
]