import React, { useState, useEffect } from "react";
import logo from '../assets/images/logo.png';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useLocation } from "react-router-dom";
import { SiUpwork } from "react-icons/si";



const Header = () => {
    const [index, setIndex] = useState(0);
    const [home, setHome] = useState('');
    const [portfolio, setPortfolio] = useState('');
    const [careers, setCareers] = useState('');
    const [contactUs, setContactUs] = useState('')
    const location = useLocation();
    const contentArray = ['Online Bidder', 'React Native Developer'];
    const NextContent = () => {
        setIndex(prevIndex => (prevIndex + 1) % contentArray.length);
    };

    const PreviousContent = () => {
        setIndex(prevIndex => (prevIndex - 1 + contentArray.length) % contentArray.length);
    };

    useEffect(() => {
        if (location.pathname === '/') {
            setHome('selected');
            setPortfolio('');
            setCareers('');
            setContactUs('');
        } else if (location.pathname === '/portfolio') {
            setHome('');
            setPortfolio('selected');
            setCareers('');
            setContactUs('');

        } else if (location.pathname === '/careers') {
            setHome('');
            setPortfolio('');
            setCareers('selected');
            setContactUs('');
        } else if (location.pathname === '/contact-us') {
            setHome('');
            setPortfolio('');
            setCareers('');
            setContactUs('selected');
        }
    }, [location])
    return (
        <>
            {/* top-nav */}
            <div className="top-nav">
                <div className="container">
                    <div className="top-nav-wrapper d-flex justify-content-between align-items-center">
                        <div className="left-topbar d-flex ">
                            <h5 className="gap-topnav"><span>Call Us:</span> <a href="tel:+911724102716">0172-4102716</a></h5>
                            <h5><span>Email Us:</span> <a href="mailto:info@squadminds.com"> info@squadminds.com</a></h5>
                        </div>
                        <div className="right-topbar d-flex align-items-center">
                            <div className="gap-topnav d-flex align-items-center">
                                <div className="openings">
                                    <div className="d-flex align-items-center">
                                        <span>Recent Openings:</span>
                                        <h5 id="content" style={{ marginLeft: '3px' }}>{contentArray[index]}</h5>
                                    </div>
                                </div>
                                <button id="prev_button" className="arrows-btn" onClick={PreviousContent}><i className="fas fa-chevron-left"></i></button>
                                <button id="next_button" className="arrows-btn" onClick={NextContent}><i className="fas fa-chevron-right"></i></button>
                            </div>
                            <div className="position-relative explore-btn d-flex">
                                <Link to="/careers" className="explore-btn">Explore More</Link>
                                <i className="fas fa-chevron-right exp-arrow"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* header section  */}
            <header className="header-wrapper">
                <Navbar expand="lg" className="bg-body-tertiary">
                    <Container>
                        <Navbar.Brand><Link to='/'><img src={logo} width="100px" /></Link></Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <ul className="navbar-nav mx-auto">
                                <li className="nav-item">
                                    <Link to='/' className={`${home} nav-linker active`} aria-current="page">Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to='/portfolio' className={`${portfolio} nav-linker `} aria-current="page">Portfolio</Link>

                                </li>
                                <li className="nav-item">
                                    <Link to='/careers' className={`${careers} nav-linker active`}>Careers</Link>
                                </li>
                            </ul>
                            <div className="contact-us-wrapper d-flex">
                                <div className="contact-us">
                                    <Link to="/contact-us" className={`${contactUs} d-flex  align-items-center`} ><i className="fa-solid fa-phone"></i>  Contact Us</Link>
                                </div>
                                <div className="social-icon social-links d-flex align-items-center">
                                        <a href="https://www.upwork.com/ag/squadminds/" target="_blank"><SiUpwork/></a>

                                        <a href="https://in.linkedin.com/company/squadmindspvtltd"
                                               target="_blank"><i
                                            className="fa-brands fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        </>
    )
}

export default Header