import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { motion } from "framer-motion";
import { TypeAnimation } from 'react-type-animation';
import django from '../assets/images/Logotestimonial/django.png';
import wordpress from '../assets/images/Logotestimonial/wordpress.png';
import firebase from '../assets/images/Logotestimonial/firebase.png';
import jquery from '../assets/images/Logotestimonial/jquery.png';
import postgres from '../assets/images/Logotestimonial/postgres.png';
import react from '../assets/images/Logotestimonial/react.png';
import rails from '../assets/images/Logotestimonial/rails.png';
import expressjs from '../assets/images/Logotestimonial/express.png';
import aws from '../assets/images/Logotestimonial/aws.jpg';
import ember from '../assets/images/Logotestimonial/ember.png';
import php from '../assets/images/Logotestimonial/php.png';
import laravel from '../assets/images/Logotestimonial/laravel.png';
import mongodb from '../assets/images/Logotestimonial/mongo.png';
import mysql from '../assets/images/Logotestimonial/mysql.png';
import magento from '../assets/images/Logotestimonial/magento.png';
import nodejs from '../assets/images/Logotestimonial/node.png';
import starIcon from '../assets/images/pngimages/star-icon.png';
import cloudData from '../assets/images/pngimages/cloud-data.png';
import chatBot from '../assets/images/pngimages/chatbot.png';
import blockChain from '../assets/images/pngimages/blockchain.png';
import Web from '../assets/images/pngimages/Web-b.png';
import iot from '../assets/images/pngimages/internet-of-things.png';
import mobileApp from '../assets/images/pngimages/mobile-app.png';
import healthcare from '../assets/images/industries/healthcare.png';
import telecommunication from '../assets/images/industries/video-conference-74406616fd29fd85418c9ead1565f260dc8f0b8f6cb393d11bdb9f87ccc7d54a.png';
import eCommerce from '../assets/images/industries/online-shop-048c6bfe90e3f07623f04c880a99ff91ece8242112641507bd6de26178b9ff15.png';
import fintech from '../assets/images/industries/fintech-ca6f1e59107e5d000290e68e177f462ddc7fd124800422d21af9956de61ec7dd.png';
import eLearning from '../assets/images/industries/online-lesson-4d9be90e04d5b771cc70082694f95c2e8cb8dd2c2cc6fd07fb643e028bdae586.png';
import crm from '../assets/images/industries/crm-e1cb9584b6e012587ef6fa03c1d5fa28eb5da332e56c62901ec467da4964daef.png';
import logistics from '../assets/images/industries/logistics-b7e809a0c35f18e8665f1b307f4e637e78278fa4e401bfd4caaed79396ef5919.png';
import realEstate from '../assets/images/industries/real-estate-54318addbaf40bfc5b0c25b1998d4084f1e417e767ba7db7547cecd992ac179e.png';
import lee from '../assets/images/clients/lee.jpg'
import nakajima from '../assets/images/clients/nakajima.jpg';
import defaultImg from '../assets/images/clients/default.png';
import vector from '../assets/images/pngimages/vector.png'
import img from '../assets/images/clients/image.png';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ProjectDetails } from "./ProjectDetails";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import AOS from "aos";
import "aos/dist/aos.css";
const Home = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000 
    };

    SwiperCore.use([Autoplay]);

    const [showButton, setShowButton] = useState(false);
    const [modal, setModal] = useState(false);
    const [projDetails, setProjDetails] = useState();
  
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    const getPrjName = (item) => {
        setProjDetails(item)
        setModal(!modal);
    }
    const handleCloseModal = (item) => {
        setModal()
    }
    useEffect(() => {
        AOS.init({
          duration: 300,
          mirror: false,
          easing: "ease-in-out",
        });
        AOS.refresh();
      }, []);
      useEffect(() => {
        setTimeout(function () {
          window.scrollTo(0, 0);
        }, 100);
      }, []);
    return (
        <>
            {/* banner */}

            {showButton && (
                <a className="scroll-to-top-button" onClick={scrollToTop}>
                </a>
            )}
            <div className="banner-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="position-relative d-flex justify-content-center">
                                <div className="leftCont-banner text-center">
                                    <div className="output" id="output">
                                        <h1 className="isParagraph">
                                            <TypeAnimation
                                                sequence={[
                                                    `Custom Software\nService That Leads The Way`,
                                                    1000,
                                                    "Outsourcing\nEmploy Top Industry Talent",
                                                    1000,
                                                    "Design Studio\nBuilds Custom Experiences",
                                                    1000
                                                ]}
                                                speed={50}
                                                style={{ whiteSpace: 'pre-line' }}
                                                repeat={Infinity}
                                            />
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* skills  */}
            <section className="technologies-wrapper">
                <div className="container">
                    <div className="parent">
                        <div className="flex-item">
                            <img src={django} />
                            <h4 style={{ color: '#092f20' }}>DJANGO</h4>
                        </div>
                        <div className="flex-item">
                            <img src={wordpress} />
                            <h4 style={{ color: '#008abb' }}>WORDPRESS</h4>
                        </div>
                        <div className="flex-item">
                            <img src={firebase} />
                            <h4 style={{ color: '#f5820b' }}>FIREBASE</h4>
                        </div>
                        <div className="flex-item">
                            <img src={jquery} />
                            <h4 style={{ color: '#0768ac' }}>JQUERY</h4>
                        </div>
                        <div className="flex-item">
                            <img src={postgres} />
                            <h4 style={{ color: '#306192' }}>POSTGRES</h4>
                        </div>
                        <div className="flex-item">
                            <img src={react} />
                            <h4 style={{ color: '#42dcf9' }}>REACT</h4>
                        </div>
                        <div className="flex-item">
                            <img src={rails} />
                            <h4 style={{ color: '#cc0000' }}>RAILS</h4>
                        </div>
                        <div className="flex-item">
                            <img src={expressjs} />
                            <h4 style={{ color: '#89be51' }}>EXPRESS JS</h4>
                        </div>
                        <div className="flex-item">
                            <img src={aws} />
                            <h4 style={{ color: '#000' }}>AWS</h4>
                        </div>
                        <div className="flex-item">
                            <img src={ember} />
                            <h4 style={{ color: '#e14a30' }}>EMBER JS</h4>
                        </div>
                        <div className="flex-item">
                            <img src={php} />
                            <h4 style={{ color: '#0079fe' }}>PHP</h4>
                        </div>
                        <div className="flex-item">
                            <img src={mongodb} />
                            <h4 style={{ color: '#4caf50' }}>MONGODB</h4>
                        </div>
                        <div className="flex-item">
                            <img src={laravel} />
                            <h4 style={{ color: '#fb3436' }}>LARAVEL</h4>
                        </div>
                        <div className="flex-item">
                            <img src={mysql} />
                            <h4 style={{ color: '#00546b' }}>MYSQL</h4>
                        </div>
                        <div className="flex-item">
                            <img src={magento} />
                            <h4 style={{ color: '#ec6737' }}>MAGENTO </h4>
                        </div>
                        <div className="flex-item">
                            <img src={nodejs} />
                            <h4 style={{ color: '#3d8c43' }}>NODE JS</h4>
                        </div>
                    </div>
                </div>
            </section>
            {/* mobile view technologies section  */}
            <div className="container">
                <section className="responsive-slider slider">
                    <Slider {...settings} >
                    <div className="slide d-flex justify-content-center">
                        <div className="flex-item justify-content-center">
                            <img src={django} />
                            <h4 style={{ color: '#092f20' }}>DJANGO</h4>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="flex-item justify-content-center">
                            <img src={wordpress} />
                            <h4 style={{ color: '#008abb' }}>WORDPRESS</h4>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="flex-item justify-content-center">
                            <img src={firebase} />
                            <h4 style={{ color: '#f5820b' }}>FIREBASE</h4>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="flex-item justify-content-center">
                            <img src={jquery} />
                            <h4 style={{ color: '#0768ac' }}>JQUERY</h4>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="flex-item justify-content-center">
                            <img src={postgres} />
                            <h4 style={{ color: '#306192' }}>POSTGRES</h4>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="flex-item justify-content-center">
                            <img src={react} />
                            <h4 style={{ color: '#42dcf9' }}>REACT</h4>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="flex-item justify-content-center">
                            <img src={rails} />
                            <h4 style={{ color: '#cc0000' }}>RAILS</h4>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="flex-item justify-content-center">
                            <img src={expressjs} />
                            <h4 style={{ color: '#89be51' }}>EXPRESS JS</h4>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="flex-item justify-content-center">
                            <img src={aws} />
                            <h4 style={{ color: '#000' }}>AWS</h4>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="flex-item justify-content-center">
                            <img src={ember} />
                            <h4 style={{ color: '#e14a30' }}>EMBER JS</h4>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="flex-item justify-content-center">
                            <img src={php} />
                            <h4 style={{ color: '#0079fe' }}>PHP</h4>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="flex-item justify-content-center">
                            <img src={mongodb} />
                            <h4 style={{ color: '#4caf50' }}>MONGODB</h4>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="flex-item justify-content-center">
                            <img src={laravel} />
                            <h4 style={{ color: '#fb3436' }}>LARAVEL</h4>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="flex-item justify-content-center">
                            <img src={mysql} />
                            <h4 style={{ color: '#00546b' }}>MYSQL</h4>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="flex-item justify-content-center">
                            <img src={magento} />
                            <h4 style={{ color: '#ec6737' }}>MAGENTO </h4>Name
                        </div>
                    </div>
                    <div className="slide">
                        <div className="flex-item justify-content-center">
                            <img src={nodejs} />
                            <h4 style={{ color: '#3d8c43' }}>NODE JS</h4>
                            </div>
                            </div>
                    </Slider>
                    </section>
            </div>
          
            {/* build of buy  */}
            <section className="custom-development-wrapper">
                <div className="container">
                    <div className="outerCs-wrapper">
                        <div className="row" >
                            <div className="col-md-6" data-aos="fade-up">
                                <div className="left-cs-develop position-relative">
                                    <h2>Build or Buy:<br /> Custom Development vs. Off-the-Shelf Software</h2>
                                    <p>Making an informed decision between custom development and off-the-shelf software that
                                        aligns
                                        with your business goals.</p>
                                    <div className="vectorImg">
                                        <img src={vector} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6" data-aos="fade-up">
                                <div className="position-relative second-inner-custom">
                                    <div className="left-cs-develop right-cs-develop">
                                        <h2>Outsourced Development: <br /> Pushing the Boundaries of Software Innovation</h2>
                                        <p>Leverage specialized talent and increased efficiency in building innovative and
                                            efficient
                                            software solutions economically.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* services we offer  */}
            <motion.div
                initial={{ opacity: 1, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{
                    ease: "linear",
                    duration: 1,
                    y: { duration: 0.5 }
                }}
            >
                <section className="services-offer-wrapper">
                    <div className="container">
                        <div className="topHeadings-wrapper mb-5 text-center">
                            <span><img src={starIcon} className="starIcon" />services we offer</span>
                            <h2>Why choose us</h2>
                            <p className="headingsP-txt title-paragrph">We convert complicated business ideas into functional solutions
                                keeping clients vision in mind</p>
                        </div>
                        <div className="row" >
                            <div className="col-lg-4" data-aos="fade-up">
                                <motion.div whileHover={{ scale: .9 }}>
                                    <div className="outerServices d-flex" style={{ cursor: 'pointer' }}>
                                        <div><img src={cloudData} /></div>
                                        <div className="ms-3">
                                            <h3>Cloud & DevOps</h3>
                                            <p>We provide the best-suited cloud-based solution combined with expertise DevOps to meet
                                                all business end needs</p>
                                        </div>
                                    </div>
                                </motion.div>
                            </div>
                            <div className="col-lg-4" data-aos="fade-up">
                                <motion.div whileHover={{ scale: .9 }}>
                                    <div className="outerServices d-flex" style={{ cursor: 'pointer' }}>
                                        <div><img src={Web} /></div>
                                        <div className="ms-3">
                                            <h3>Web Development</h3>
                                            <p>We believe in providing a wide range of customized solutions with an edge by taking
                                                various measures including cost-effective and on-time development</p>
                                        </div>
                                    </div>
                                </motion.div>
                            </div>
                            <div className="col-lg-4" data-aos="fade-up">
                                <motion.div whileHover={{ scale: .9 }}>
                                    <div className="outerServices d-flex" style={{ cursor: 'pointer' }}>
                                        <div><img src={mobileApp} /></div>
                                        <div>
                                            <h3>Mobile Apps Development</h3>
                                            <p>We deliver the best native and hybrid/ cross-platform mobile apps which are compatible,
                                                reliable, best in performance and security</p>
                                        </div>
                                    </div>
                                </motion.div>
                            </div>
                            <div className="col-lg-4" data-aos="fade-up">
                                <motion.div whileHover={{ scale: .9 }}>
                                    <div className="outerServices d-flex" style={{ cursor: 'pointer' }}>
                                        <div><img src={iot} /></div>
                                        <div className="ms-3">
                                            <h3>IOT - Internet Of Things</h3>
                                            <p>We offer our clients an effective business enabled using smart sensors and automated
                                                devices with the help of IoT</p>
                                        </div>
                                    </div>
                                </motion.div>
                            </div>
                            <div className="col-lg-4" data-aos="fade-up">
                                <motion.div whileHover={{ scale: .9 }}>
                                    <div className="outerServices d-flex" style={{ cursor: 'pointer' }}>
                                        <div><img src={blockChain} />
                                        </div>
                                        <div className="ms-3">
                                            <h3>BlockChain</h3>
                                            <p>We emphasize security and encourage our clients to apply Blockchain Technology to
                                                minimize risks</p>
                                        </div>
                                    </div>
                                </motion.div>
                            </div>
                            <div className="col-lg-4" data-aos="fade-up">
                                <motion.div whileHover={{ scale: .9 }}>
                                    <div className="outerServices d-flex" style={{ cursor: 'pointer' }}>
                                        <div><img src={chatBot} /></div>
                                        <div className="ms-3">
                                            <h3>Chat Bots & VAC</h3>
                                            <p>We support our clients with bot services that impact their business with the best
                                                customer engagement</p>
                                        </div>
                                    </div>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </section>
            </motion.div>
            {/* our capabilities  */}
            <motion.div
                initial={{ opacity: 1, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{
                    ease: "linear",
                    duration: 1,
                    y: { duration: 0.5 }
                }}
            >
                <section className="cabibilities-wrapper">
                    <div className="container">
                        <div className="topHeadings-wrapper mb-5 text-center">
                            <span><img src={starIcon} className="starIcon" />INDUSTRIES WE SERVE</span>
                            <h2>Explore our capabilities by industry</h2>
                            <p className="headingsP-txt title-paragrph">A sneak peek into the different types of industries we cater to
                                and offering technical solutions across the globe</p>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-sm-6 col-md-6" data-aos="fade-up">
                                <div className="single-industries-serve-box">
                                    <img alt="Healthcare" src={healthcare} />HealthCare
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-md-6" data-aos="fade-up">
                                <div className="single-industries-serve-box">
                                    <img alt="Healthcare" src={telecommunication} />TeleCommunication
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-md-6" data-aos="fade-up">
                                <div className="single-industries-serve-box">
                                    <img alt="Healthcare" src={eCommerce} />ECommerce
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-md-6" data-aos="fade-up">
                                <div className="single-industries-serve-box">
                                    <img alt="Healthcare" src={fintech} />Fintech
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-md-6" data-aos="fade-up">
                                <div className="single-industries-serve-box">
                                    <img alt="Healthcare" src={eLearning} />ELearning
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-md-6" data-aos="fade-up">
                                <div className="single-industries-serve-box">
                                    <img alt="Healthcare" src={crm} />CRM
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-md-6" data-aos="fade-up">
                                <div className="single-industries-serve-box">
                                    <img alt="Healthcare" src={logistics} />Logistics
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-md-6" data-aos="fade-up">
                                <div className="single-industries-serve-box">
                                    <img alt="Healthcare" src={realEstate} />RealEstate
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </motion.div>

            {/* our projects   */}
            <motion.div
                initial={{ opacity: 1, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{
                    ease: "linear",
                    duration: 1,
                    y: { duration: 0.5 }
                }}
            >
                <div className="wrapper">
                    <div className="container">
                        <div className="topHeadings-wrapper mb-5 text-center">
                            <span><img src={starIcon} className="starIcon" />OUR RECENT PROJECTS</span>
                            <h2>We’re happy to showcase wide variety of our work</h2>
                        </div>
                        <div className="our-projects-slider" data-aos="fade-up">
                            <Swiper
                                breakpoints={{
                                1200: {
                                slidesPerView: 3,
                                },
                                    1024: {
                                        slidesPerView: 2,
                                    },
                                768: {
                                slidesPerView: 2,
                                    },
                                   576: {
                                slidesPerView: 1,
                                    },
                                   
                                }}
                                autoplay={{ delay: 3000 }}
                                spaceBetween={30}
                                pagination={{
                                    clickable: true,
                                }}
                                modules={[Autoplay]}
                                className="mySwiper"
                            >
                                {ProjectDetails.slice(0, 7).map((item,index) => {
                                    return (
                                        <>
                                            <SwiperSlide key={index}>
                                                <div className="projects-wrap text-center" onClick={() => getPrjName(item)}>
                                                    <div className="project-img" >
                                                        <img src={item.image} style={{ cursor: 'pointer' }} />
                                                    </div>
                                                    <div className="portfolio-txt text-center">
                                                        <h4><a href='#' tabIndex="_blank">{item.name}</a></h4>
                                                        <h5>{item.lang}</h5>
                                                    </div>
                                                </div>

                                            </SwiperSlide>
                                        </>
                                    )
                                })}
                                <div className="text-center" style={{ marginBottom: '25px' }}>
                                    <Link to="/portfolio" className="banner-btn mt-0">See all projects</Link>
                                </div>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </motion.div>
            {/* client speech  */}
            <motion.div
                initial={{ opacity: 1, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{
                    ease: "linear",
                    duration: 1,
                    y: { duration: 0.5 }
                }}
            >
                <section className="clients-speech overflow-hidden">
                    <div className="container">
                        <div className="project-title topHeadings-wrapper text-center">
                            <span><img src={starIcon} className="starIcon" />OUR CLIENT’S SPEECH</span>
                            <h2 className="m-0">Check out our rewards earned with special recognition & appreciation</h2>
                        </div>
                        <div className="gtco-testimonials" data-aos="fade-up">
                            <div className="swip">
                                <Swiper
                                    breakpoints={{
                                        1200: {
                                            slidesPerView: 3,
                                        },
                                        1024: {
                                            slidesPerView: 2,
                                        },
                                        768: {
                                            slidesPerView: 2,
                                        },
                                        576: {
                                            slidesPerView: 1,
                                        },
                                    }}
                                    autoplay={{ delay: 3000 }}
                                    spaceBetween={10}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Autoplay]}
                                    className="mySwiper"

                                >
                                    <SwiperSlide key={23}>
                                        <div className="card text-center"><img className="card-img-top" src={lee} alt="" />
                                            <div className="card-body">
                                                <h5>Lee Jones</h5>
                                                <p className="card-text">“They are extremely professional at delivery. Fantastic work ethic
                                                    and delivered in TOT. I would recommend their services to anyone. ” </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide key={24}>
                                        <div className="card text-center"><img className="card-img-top" src={nakajima}
                                            alt="" />
                                            <div className="card-body">
                                                <h5>Akinori Nakajima</h5>
                                                <p className="card-text">“Everything delivered well on the React and ROR based web
                                                    application, they were one of the primary contributors from the start. They are
                                                    disciplined, open to feedback and produces a good quantity of work within the
                                                    deadline.” </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide key={25}>
                                        <div className="card text-center"><img className="card-img-top" src={defaultImg} alt="" />
                                            <div className="card-body">
                                                <h5>Olle Bohlin</h5>
                                                <p className="card-text">“Working with this team was brilliant from start to finish.
                                                    Explained what they were going to do upfront and produced an outstanding piece of
                                                    work for us. Will be using this Team again for sure.” </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide key={26}>
                                        <div className="card text-center"><img className="card-img-top" src={defaultImg} alt="" />
                                            <div className="card-body">
                                                <h5>Oliver Jutzi</h5>
                                                <p className="card-text">“It was great working with SquadMinds Team, Very Cooperative.
                                                    Highly recommend.” </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide key={27}>
                                        <div className="card text-center"><img className="card-img-top" src={img} alt="" />
                                            <div className="card-body">
                                                <h5>Richard Oberlander</h5>
                                                <p className="card-text">“Very happy with the work that was done. I am not a tech guy and
                                                    eventually, everything all worked out and we have a fully functional app that does
                                                    exactly what we needed. SquadMinds ensures everything is done to satisfaction.” </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide key={28}>
                                        <div className="card text-center"><img className="card-img-top" src={defaultImg} alt="" />
                                            <div className="card-body">
                                                <h5>Joel Unge</h5>
                                                <p className="card-text">“Really good developers. Will definitely want to work with
                                                    Squadminds again!” </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </section>
            </motion.div>
        </>
    )
}


export default Home